import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-dropdown-car-value"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "p-dropdown-car-option" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString(_ctx.props) + " ", 1),
    _createVNode(_component_Dropdown, {
      modelValue: $setup.screen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.screen) = $event)),
      options: 
      $setup.screenList.filter(f => f.parentId == null)
        .map((o) => {
          return { name: o.displayName, value: o.id };
        })
    ,
      optionLabel: "name",
      filter: true,
      placeholder: "Seçiniz",
      showClear: true,
      onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($event))),
      style: {"width":"100%"}
    }, {
      value: _withCtx((slotProps) => [
        (slotProps.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(slotProps.value.name), 1)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.placeholder), 1))
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(slotProps.option.name), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ], 64))
}