
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const screen: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.SCREEN_LIST);
    const screenList = computed(
      () => store.state.ScreenModule.screen.value || []
    );

    const onChange = (args) => {        
      emit("changeScreen", args);
    };
    return { close, screen, store, screenList, onChange };
  },
};
